import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import TableCarousel from '../Components/TableCarousel';
import "./quests.scss";
import { useWallet } from '../Context/WalletContext';  // Import the WalletContext hook
import axios from "axios";
import ZcToken from '../images/zc-token.png';  // Add this import
import Img from "../Layout/Img";
import QuestBanner from "../images/portal/quest-banner.webp";

// import GameBTFD from '../images/portal/game-btfd-png.png';
// import GameEVM from '../images/portal/game-evm-png.png';
// import GameChooChoo from '../images/portal/game-choochoo-png.png';
// import GameSugarRush from '../images/portal/game-sugarrush-png.png';
// import GamePepeKingdom from '../images/portal/game-pepekingdom-png.png';
// import GameCandyDefense from '../images/portal/game-candydefence-png.png';
// import GameKittySlash from '../images/portal/game-kittyslash-png.png';
import GalaxeLogo from '../images/galaxeLogo.png';
import UsergroupIcon from '../images/icon-usergroup.png';

import GameEVM from '../images/portal/game-evm-png.png';
import GameChooChoo from '../images/portal/game-choochoo-png.png';
import GameSugarRush from '../images/portal/game-sugarrush-png.png';

export default function Quests() {
  const { walletAddress, isConnected } = useWallet();  // Access the wallet context

  // const { isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();
  const [gamePoints, setGamePoints] = useState({});
  const [totalPoints, setTotalPoints] = useState(0);

  const sendRequest = async (data, url, method = "post", params = null) => {
    let config = {
      method: method,
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (data) config.data = JSON.stringify(data);
    if (params) config.params = params;
    console.debug("config: ", config);
    return await axios.request(config).catch(function (error) {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
  };

  useEffect(() => {
    if (!isConnected) {
      navigate('/login');
    }

    const fetchData = async () => {
      if (isConnected && walletAddress) {
        try {
          // Fetch total points
          const totalPointsResponse = await sendRequest(
            {
              action: "getTotalPoints",
              walletAddress: walletAddress,
            },
            `${process.env.REACT_APP_API_URL}`
          );
          // console.log('totalPointsResponse: ' + JSON.stringify(totalPointsResponse.data.data));
          if (totalPointsResponse.status === 200 && totalPointsResponse.data) {
            setTotalPoints(totalPointsResponse.data.data.totalPoints || 0);
          }

          // Fetch game points
          const gameNames = ["EVM Warfare", "Sugar Rush", "Choo Choo Spirits"];
          const gamePointsData = {};
          for (const gameName of gameNames) {
            const gameResponse = await sendRequest(
              {
                action: "getUserPointsByGame",
                walletAddress: walletAddress,
                gameName: gameName,
              },
              `${process.env.REACT_APP_API_URL}`
            );
            if (gameResponse.status === 200 && gameResponse.data) {
              gamePointsData[gameName] = gameResponse.data.data || 0;
            }
          }
          setGamePoints(gamePointsData);

        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, [isConnected, navigate]);

  const featuredData = {
    headerTitle: 'Featured Quests',
    headerTitleColor: '#FFCC5A',
    // headerSubTitle: 'Milestone',
    // headerSubTitle2: 'Points/Max',
    tableType: 'main',
    tableSubHeader: 'Begin your Deez Journey now',
    data: [
      {
        textColor: '',
        icon: GalaxeLogo,
        title: 'Voyager\'s NFT Expedition',
        titleClassName: 'strike-through',
        subTitle: 'Follow and retweet on @Zkcandyhq on X [Twitter], join our Telegram and Discord, and mint an NFT in Sugar Rush to earn your Voyager Pass NFT and Candy Points.',
        milestone: 140,
        button: [
          {
            type: 'disabled',
            icon: GalaxeLogo,
            buttonText: 'Ended',
            link: 'https://app.galxe.com/quest/VFwxFFsaFfESCUC6iHfzp9/GCAAutxG3i',
            target: '_blank',
            disabled: true
          },
        ]
      },
      {
        icon: GalaxeLogo,
        textColor: '',
        title: 'Points Frenzy Challenge',
        subTitle: 'Play our exciting ZKcandy games like Sugar Rush, EVM Warfare, and Choo Choo Spirits to climb the leaderboards and earn Candy Points!',
        milestone: 140,
        button: [
          {
            type: '',
            icon: GalaxeLogo,
            buttonText: 'Join',
            link: 'https://app.galxe.com/quest/VFwxFFsaFfESCUC6iHfzp9/GC4obtx5bc',
            target: '_blank',
          },
        ]
      },
      // {
      //   textColor: '#FFCC5A',
      //   icon: UsergroupIcon,
      //   title: 'ZKcandy Homie',
      //   subTitle: 'Refer a friend who earns 1,000 Quests points this season',
      //   milestone: 200,
      //   milestoneDesc: '/Friend',
      //   point: 0,
      //   maxPoint: 5000,
      //   button: [
      //     {
      //       type: 'secondary',
      //       buttonText: 'Referral Code',
      //       link: '/my-points'
      //     },
      //   ]
      // }
    ]
  };

  const gamesData = {
    tableType: 'main',
    tableSubHeader: 'Game Points',
    tableSubHeaderLink: '/#game',
    tableSubHeaderButtonText: 'Games',
    tableSubHeaderInfo: 'Game points are updated once every week on Monday. Automatic updates are coming soon.',
    data: [
      {
        textColor: '#FFCC5A',
        icon: GameEVM,
        title: 'EVM Warfare',
        milestone: 1500,
        milestoneDesc: '/Friend',
        point: 0,
        maxPoint: 7500,
      },
      {
        textColor: '#FFCC5A',
        icon: GameSugarRush,
        title: 'Sugar Rush',
        milestone: 100,
        milestoneDesc: '/Candy NFT',
        point: 0,
        maxPoint: 7500,
      },
      {
        textColor: '#FFCC5A',
        icon: GameChooChoo,
        title: 'Choo Choo Spirits',
        milestone: 100,
        milestoneDesc: '',
        point: 0,
        maxPoint: 7500,
      }
    ]
  };

  // Update game details
  const updatedGamesData = {
    ...gamesData,
    data: gamesData.data.map((game) => ({
      ...game,
      point: gamePoints[game.title] || 0
    }))
  };

  // const seasonalData = {
  //   headerTitle: 'Seasonal Quests',
  //   headerTitleColor: '',
  //   headerSubTitle: 'Milestone',
  //   headerSubTitle2: 'Points/Max',
  //   tableType: '',
  //   data: [
  //     {
  //       icon: UsergroupIcon,
  //       textColor: '#FFCC5A',
  //       title: 'Questy Bestie',
  //       subTitle: 'Refer a friend who earns 5,000 Quests points this season',
  //       milestone: 500,
  //       milestoneDesc: '/Friend',
  //       button: [
  //         {
  //           type: 'secondary',
  //           buttonText: 'Referral Code',
  //           link: '/my-points'
  //         },
  //       ]
  //     },
  //     {
  //       icon: UsergroupIcon,
  //       textColor: '#FFCC5A',
  //       title: 'Questy Bestie',
  //       subTitle: 'Refer a friend who earns 17,000 Quests points this season',
  //       milestone: 1500,
  //       milestoneDesc: '/Friend',
  //       button: [
  //         {
  //           type: 'secondary',
  //           buttonText: 'Referral Code',
  //           link: '/my-points'
  //         },
  //       ]
  //     },
  //     {
  //       icon: UsergroupIcon,
  //       textColor: '#FFCC5A',
  //       title: 'Regal Domination',
  //       subTitle: 'Complete the collection of all 8 Sugar Rush Candy NFTs.',
  //       milestone: 100,
  //       milestoneDesc: '/Candy NFT',
  //       button: [
  //         {
  //           type: 'secondary',
  //           buttonText: 'Referral Code',
  //           link: '/my-points'
  //         },
  //       ]
  //     },
  //   ]
  // };

  // const gameContent = {
  //   type: 2,
  //   cardname: 'game',
  //   subTitle: 'Play To Airdrop',
  //   title: '',
  //   descTop: '',
  //   descBottom: 'Play our games and earn Candy Points while you\'re at it',
  //   details: [
  //     {
  //       name: 'Sugar \n Rush',
  //       desc: '',
  //       img: GameSugarRush,
  //       link: 'https://sugarrush-testnet.zkcandy.io/',
  //     },
  //     {
  //       name: 'EVM Warfare',
  //       desc: '',
  //       img: GameEVM,
  //       link: 'https://evmwarfare.com/',
  //     },
  //     {
  //       name: 'Choo Choo Spirit',
  //       desc: '',
  //       img: GameChooChoo,
  //       link: 'https://choochoospirits.io/',
  //     },
  //     {
  //       name: 'BTFD.WTF',
  //       desc: '',
  //       img: GameBTFD,
  //       link: 'https://btfd.wtf/',
  //     },
  //     {
  //       name: 'Pepe Kingdom',
  //       desc: '',
  //       img: GamePepeKingdom,
  //       link: 'https://t.me/pepe_kingdom_bot',
  //     },
  //     {
  //       name: 'Candy Defense',
  //       desc: '',
  //       img: GameCandyDefense,
  //       link: 'https://t.me/CandyDefense_bot',
  //     },
  //     {
  //       name: 'Kitty Slash',
  //       desc: '',
  //       img: GameKittySlash,
  //       link: 'https://t.me/kittyslash_bot',
  //     },
  //   ],
  //   caption: 'More games coming soon',
  // };

  // const CardWithHeader = ({ cardWithHeader }) => (
  //   <div id={cardWithHeader.cardname} className={`portal-header-frame type-${cardWithHeader.type} card-${cardWithHeader.cardname}`}>
  //     <div className="portal-frame">
  //       <div className="sub-title text-center">{cardWithHeader.subTitle}</div>
  //       <div className="title text-center">{cardWithHeader.title}</div>

  //       <div className="text-desc-top text-center">{cardWithHeader.descTop}</div>
  //       <div className="text-desc-bottom text-center">{cardWithHeader.descBottom}</div>


  //       <div className="cards flex flex-wrap justify-center -mx">
  //         {cardWithHeader.details.map((card, index) => (
  //           <div key={index} className='w-1/2 md:w-1/4 lg:w-1/4 px-3'>
  //             <div className="box small" rel="noopener noreferrer">
  //               <a className="box-thumb" href={card.link} target="_blank">
  //                 <img src={card.img} alt="" className="" />
  //               </a>
  //               {cardWithHeader.cardname === 'guide' ? (
  //                 <div className="box-desc">
  //                   <a className="title" href={card.link} target="_blank">{card.name}</a>
  //                   {card.languages != null && (
  //                     <div className='languages flex flex-wrap'>
  //                       {card.languages.map((lang, langIndex) => (
  //                         <a href={lang.link} className='language border-solid border-2 border-white me-2 mb-2 px-2' key={langIndex}>
  //                           {lang.lang}
  //                         </a>
  //                       ))}
  //                     </div>
  //                   )}
  //                 </div>
  //               ) : (
  //                 <a className="box-desc" href={card.link} target="_blank">
  //                   <div className="title">{card.name}</div>
  //                   <div className="sub-title">{card.desc}</div>
  //                 </a>
  //               )}
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //       {cardWithHeader.type === 2 && (<div className="caption flex text-center justify-center">{cardWithHeader.caption}</div>)}
  //     </div>
  //   </div>
  // );

  return (
    <main className="quests tracking-tighter text-white font-normal leading-7">
      <div className="text-banner flex justify-center">
        <div className="container flex justify-center text-center">
          Compete for 100 mil Candy Points in Season Zero
        </div>
      </div>

      <div className="bg-[#00000040]">
        <div className="text-season flex justify-between items-center container mx-auto">
          <div className="text-[#FFCC5A]">Season Zero</div>
          <div className="token flex items-center">
                <div className="w-[4rem]">
                  <Img
                    src={ZcToken}
                    alt="logo"
                    className="w-100"
                  />
                </div>
                <div className="candy-point">
                  <div className="value text-start">{totalPoints}</div>
                  <div className="text-[1.2rem] leading-none tracking-[-.25rem] text-right">Candy points</div>
                </div>
              </div>
        </div>
      </div>

      <div className=" pb-10">
        <div className="container m-auto">
          <TableCarousel tableData={featuredData} />
          {/* <TableCarousel tableData={seasonalData} /> */}
          {/* <CardWithHeader cardWithHeader={gameContent} /> */}
          {/* <TableCarousel tableData={updatedGamesData} /> */}

          <img src={QuestBanner} alt="Quest" className="rounded-[2rem] border-2" />
        </div>
      </div>
    </main>
  );
}