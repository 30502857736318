import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [isKOL, setIsKOL] = useState(false);
  const { address } = useWeb3ModalAccount();
  const navigate = useNavigate();

  const checkTokenValidity = useCallback(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.expiresIn < currentTime) {
          logout();
        } else if(decodedToken.walletAddress !== address) { 
          logout()
        } else {
          setIsAuthenticated(true);
          setAuthToken(token);
          setIsKOL(decodedToken.isKOL || false); 
        }
      } catch (error) {
        console.error('Invalid token:', error);
        logout();
      }
    } else {
      setIsAuthenticated(false);
      setIsKOL(false);
    }
  }, []);

  useEffect(() => {
    checkTokenValidity();
  }, [checkTokenValidity]);

  const login = useCallback((token) => {
    localStorage.setItem('jwt', token);
    console.log('logging in')
    setAuthToken(token);
    console.log('auth token: ' + token)
    setIsAuthenticated(true);
     try {
      const decodedToken = jwtDecode(token);
      setIsKOL(decodedToken.isKOL || false);
    } catch (error) {
      console.error('Error decoding token:', error);
      setIsKOL(false);
    }
    // checkTokenValidity();
    navigate('/quests')
  }, [checkTokenValidity]);

  const logout = useCallback(() => {
    localStorage.removeItem('jwt');
    setIsAuthenticated(false);
    setAuthToken(null);
    setIsKOL(false);
  }, []);

  const value = {
    isAuthenticated,
    isKOL,
    logout,
    login,
    authToken,
    checkTokenValidity,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);